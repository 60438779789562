// app/providers.js
'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { useEffect } from 'react'; // Add the missing import statement for React
import PostHogPageView from './PHPageView';
import { z } from 'zod';
import { capture, identifyUser } from '@/config/captureUserEvent';
import { EventNames } from '@/config/eventNames';
export function PostHogClientSideProviders({
  children,
  bootstrapData,
}: {
  children: React.ReactNode;
  bootstrapData: {
    distinctID: string;
    featureFlags: Record<string, string | boolean>;
  };
}) {
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    console.log('Initializing PostHog');
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      bootstrap: bootstrapData,
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true, // Enable pageleave capture,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true, // Highly recommended as a minimum!!
          color: false,
          date: false,
          'datetime-local': false,
          email: false,
          month: false,
          number: false,
          range: false,
          search: false,
          tel: false,
          text: false,
          time: false,
          url: false,
          week: false,
          textarea: false,
          select: false,
        },
      },
    });
  } else {
    console.log('PostHog not initialized');
  }

  function onTidioChatApiPreFormFilled({ data }: any) {
    if (data) {
      const email = data.form_data.email;
      z.string().email().parse(email);
      identifyUser(email);
      capture(EventNames.tidioChatPreFormFilled);
    }
  }

  useEffect(() => {
    document.addEventListener(
      'tidioChat-preFormFilled',
      onTidioChatApiPreFormFilled,
    );

    return () => {
      document.removeEventListener(
        'tidioChat-preFormFilled',
        onTidioChatApiPreFormFilled,
      );
    };
  }, []);

  // Specify the type for the 'children' prop
  return (
    <PostHogProvider client={posthog}>
      <PostHogPageView />
      <CalendlyEventsProvider>{children}</CalendlyEventsProvider>
    </PostHogProvider>
  );
}

export function CalendlyEventsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    function isCalendlyEvent(e: MessageEvent) {
      return e.data.event && e.data.event.includes('calendly');
    }

    async function handlePostMessage(e: MessageEvent) {
      if (isCalendlyEvent(e)) {
        console.log(e.data);
        posthog.capture(e.data.event, e.data.payload);
        const { done } = await fetch('/api/calendly', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(e.data),
        }).then((res) => res.json());

        if (done) {
          console.log('Posthog identify done');
        }
      }
    }

    window.addEventListener('message', handlePostMessage);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return <>{children}</>;
}
