export enum EventNames {
  // Landing Page Event
  freeTrialLandingPageViewed = 'me.free_trial_landing_page_viewed',

  // Form-related Events
  freeTrialBookCtaClicked = 'me.free_trial_book_cta_clicked',
  freeTrialFormStarted = 'me.free_trial_form_started',
  freeTrialFormStep1Completed = 'me.free_trial_form_step_1_completed',
  freeTrialTimeSlotSelected = 'me.free_trial_time_slot_selected',
  freeTrialDateSelected = 'me.free_trial_date_selected',
  freeTrialFormCompleted = 'me.free_trial_form_completed',
  freeTrialFormAbandoned = 'me.free_trial_form_abandoned',

  freeTrialBookingConfirmationViewed = 'me.free_trial_booking_confirmation_viewed',

  // Back Button Events
  freeTrialBackButtonClickedStep1 = 'me.free_trial_back_button_clicked_step_1', // Back button on Step 1
  freeTrialBackButtonClickedStep2 = 'me.free_trial_back_button_clicked_step_2', // Back button on Step 2
  freeTrialBackButtonClickedTimeSlot = 'me.free_trial_back_button_clicked_time_slot', // Back button on Time Slot selection

  // Back Button Events
  freeTrialModalClosed = 'me.free_trial_modal_closed', // User clicks on modal overlay to close or esc

  // Form Field Input Events
  freeTrialFieldInputStudentName = 'me.free_trial_field_input_student_name',
  freeTrialFieldInputEmailAddress = 'me.free_trial_field_input_email_address',
  freeTrialFieldInputPhoneNumber = 'me.free_trial_field_input_phone_number',
  freeTrialFieldInputPersonBooking = 'me.free_trial_field_input_person_booking',

  // Dropdowns / Select Inputs
  freeTrialFieldInputPrimaryInstrument = 'me.free_trial_field_input_primary_instrument',
  freeTrialFieldInputAgeBracket = 'me.free_trial_field_input_age_bracket',
  freeTrialFieldInputLocation = 'me.free_trial_field_input_location',
  freeTrialFieldInputHowDidYouHearAboutUs = 'me.free_trial_field_input_how_did_you_hear_about_us',

  // Additional Input Fields
  freeTrialFieldInputMusicExperience = 'me.free_trial_field_input_music_experience',

  // TidioChat Events
  tidioChatPreFormFilled = 'me.tidio_chat_pre_form_filled',
}
