import posthog from 'posthog-js';
import { EventNames } from './eventNames';

/**
 * Helper function to capture a user event
 * @param eventName - The name of the event (from EventNames enum)
 * @param eventData - Optional data to pass with the event (e.g., field values)
 */
export function capture(
  eventName: EventNames,
  eventData: Record<string, any> = {},
) {
  if (process.env.NEXT_PUBLIC_FLAG_CAPTURE_USER_EVENTS === 'true') {
    // PostHog event capture
    posthog.capture(eventName, eventData);

    // Dispatch CustomEvent for Google Tag Manager (GTM) or other tracking systems
    document.dispatchEvent(
      new CustomEvent(eventName, {
        detail: eventData,
      }),
    );
  }

  // Log the event to the console for debugging
  console.debug('User Event:', eventName, eventData);
}

export function identifyUser(email: string) {
  posthog.identify(email, { email });
}
